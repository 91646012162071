/** @format */

import React from "react";
import Hero from "../components/element/Hero";
import Section1 from "../components/element/Section1";
import Section2 from "../components/element/Section2";
import Section3 from "../components/element/Section3";
import Section4 from "../components/element/Section4";
import Section5 from "../components/element/Section5";
import Section6 from "../components/element/Section6";
import Section7 from "../components/element/Section7";

const Home = () => {
  return (
    <React.Fragment>
      {/* <div id='preloader' /> */}

     

      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </React.Fragment>
  );
};

export default Home;

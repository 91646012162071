import React from 'react'

const Section6 = () => {
  return (
    <section className='testimonial-area'>
        <div className='container'>
          <div className='row'>
            <div className='section-titleV1'>
              <h3 data-aos-delay={100} data-aos-duration={1000}>
                Product <span>Timeline</span>
              </h3>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 pb-5 mb-5'>
              <img src='assets/img/platform/roadmap.png' alt='...' />
            </div>
          </div>
        </div>
      </section>  )
}

export default Section6
/** @format */

import React from "react";
import {
  FaDiscord,
  FaGithub,
  FaInstagram,
  FaMediumM,
  FaReddit,
} from "react-icons/fa";
import { BsMedium } from "react-icons/bs";
const Footer = () => {
  return (
    <footer className='footer-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4 text-center offset-lg-4'>
            <div className='f-widget fw-logo-text'>
              <img src='assets/img/logo3.png' alt='Logo' />
              <p>A True Decentralized Communication platform</p>
              <div className='footer-social'>
                <ul className='social-list'>
                  <li>
                    <a
                      rel='noreferrer'
                      target='_BLANK'
                      href='https://secretpep.medium.com/'>
                      <BsMedium />
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noreferrer'
                      target='_BLANK'
                      href='https://twitter.com/SecretPep_'>
                      <i className='fa fa-twitter' aria-hidden='true' />
                    </a>
                  </li>

                  <li>
                    <a
                      target='_BLANK'
                      href='https://t.me/Secret_pep'
                      rel='noreferrer'>
                      <i className='fa fa-telegram' aria-hidden='true' />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_BLANK'
                      href='https://discord.gg/SUnBNkhvRc'
                      rel='noreferrer'>
                      <FaDiscord />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_BLANK'
                      href='https://www.reddit.com/r/Secret_Pep'
                      rel='noreferrer'>
                      <FaReddit />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_BLANK'
                      href='https://github.com/secretpep'
                      rel='noreferrer'>
                      <FaGithub />
                    </a>
                  </li>
                  <li>
                    <a
                      target='_BLANK'
                      href='https://www.instagram.com/secretpep_/'
                      rel='noreferrer'>
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-copyright'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <div className='copyright-text'>
                <p>
                  2023 <i className='fa fa-code' /> Powered By (Secret pep Team)
                  SST! Email us: team@secretpep.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/** @format */

import React from "react";

const Section1 = () => {
  return (
    <section>
      <div className='featured'>
        <hr />
        <div className='container-fluid pt-5 mt-0'>
          {/* <div class="row pt-0 mt-0 pb-0 ">
         <div class="col-lg-12">
            <h3 class="text-center">As seen on</h3>
         </div>
      </div> */}
          <div className='row mt-4 pt-1 pb-5 text-center'>
            <div className='col-md-2 col-6'>
              <a
                rel='noreferrer'
                target='_BLANK'
                href='https://blocksafu.com/audit/0x69b13Bb8B87615F79a6a635469286E090B591BCF'>
                <img src='assets/img/media/m1.svg' alt='...' width='180px' />
              </a>
            </div>
            <div className='col-md-2 col-6'>
              <a
                target='_BLANK'
                rel='noreferrer'
                href='https://bscscan.com/address/0x69b13Bb8B87615F79a6a635469286E090B591BCF'>
                <img src='assets/img/media/m2.svg' alt='..' width='160px' />
              </a>
            </div>
            <div className='col-md-3 col-6' style={{ marginTop: "-10px" }}>
              <a
                // target='_BLANK'
                rel='noreferrer'
                href='#!'>
                <img src='assets/img/media/m3.png' alt='...' width='180px' />
              </a>
            </div>
            <div className='col-md-2  col-6'>
              <a
                target='_BLANK'
                rel='noreferrer'
                href='https://www.dexview.com/bsc/0x69b13Bb8B87615F79a6a635469286E090B591BCF'
                rel='noreferrer'>
                <img src='assets/img/media/m4.svg' alt='...' width='200px' />
              </a>
            </div>
            <div className='col-md-3 mt-1'>
              <a
                target='_BLANK'
                rel='noreferrer'
                href='https://pancakeswap.finance/swap?outputCurrency=0x69b13Bb8B87615F79a6a635469286E090B591BCF'
                rel='noreferrer'>
                <img src='assets/img/media/m5.png' alt='...' width='200px' />
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  );
};

export default Section1;

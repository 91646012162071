import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/layout/Base";
import Privacy from "./pages/Privacy";
const Router = () => {
  return (
    <BrowserRouter>
    <Base>
      <Routes>
        <Route index path='/' element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />

      </Routes>
    </Base>
  </BrowserRouter>
  )
}

export default Router
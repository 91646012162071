/** @format */

import React from "react";

const Section5 = () => {
  return (
    <section className='achievement-area'>
      <div className='graphicanimation-area'>
        <div className='ga-set-A'>
          <ul>
            <li>
              <img src='assets/img/graphic/set-a/g-1.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-2.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-3.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-4.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-5.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-6.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-8.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-9.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-10.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-11.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-12.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-13.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-14.png' alt='' />
            </li>
          </ul>
        </div>
      </div>
      <div className='container' id='SPEP-token'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='achievement-text'>
              <div className='section-titleV1 pb-0'>
                <p
                  className='mb-0 pb-0'
                  data-aos-delay={50}
                  data-aos-duration={1000}>
                  Total Supply: 250,000,000,000,000 $SecretPEP
                </p>
                <h3
                  className='mt-0 pt-0'
                  style={{ fontSize: "39px" }}
                  data-aos-delay={100}
                  data-aos-duration={1000}>
                  Token
                  <span style={{ display: "inline-block" }}>Distribution</span>
                </h3>
              </div>
              <div className='section-text'>
                <ul>
                  <li className='mb-5 mt-5 pt-5'>
                    <span
                      style={{
                        backgroundColor: "#29F9C6",
                        padding: "0px 10px",
                        color:"#fff"

                      }}>
                      Presale:
                    </span>{" "}
                    33.8% tokens of tokens are available in presale via
                    Pinksale.
                  </li>
                  <li className='mb-5'>
                    <span
                      style={{
                        backgroundColor: "#DB86DE",
                        padding: "0px 10px",
                        color:"#fff"
                      }}>
                      Initial Liquidity Pool:
                    </span>{" "}
                    16.37% of tokens will be used to provide liquidity to
                    exchanges
                  </li>

                  <li className='mb-5'>
                    {" "}
                    <span
                      style={{
                        backgroundColor: "#E6E3B8",
                        padding: "0px 10px",
                        color:"#fff"

                      }}>
                      Staking Pool 45 days:
                    </span>
                    18% of tokens are allocated for Staking reward pool (2nd
                    Round).
                  </li>
                  <li className='mb-5'>
                    {" "}
                    <span
                      style={{
                        backgroundColor: "#9CB3FF",
                        padding: "0px 10px",
                        color:"#fff"

                      }}>
                      Staking Pool 45 days:
                    </span>
                    16% of tokens are allocated for Staking reward pool (3rd
                    Round).
                  </li>
                  <li className='mb-5'>
                    <span
                      style={{
                        backgroundColor: "#C6C174",
                        padding: "0px 10px",
                        color: "#fff",
                      }}>
                      Bounty Reward & Airdrop:
                    </span>{" "}
                    2.82% tokens winning by participating in airdrop and bounty
                    reward
                  </li>
                  <li className='mb-5'>
                    <span
                      style={{
                        backgroundColor: "#FBE280",
                        padding: "0px 10px",
                        color: "#fff",
                      }}>
                      Team Found:
                    </span>{" "}
                    2% team  tokens locked for 4 months
                  </li>
                  <li className='mb-5'>
                    <span
                      style={{
                        backgroundColor: "#FFAABC",
                        padding: "0px 10px",
                        color: "#fff",
                      }}>
                      Marketing &amp; Strategic Partnership:
                    </span>{" "}
                    1% .
                  </li>
                  <li className='mb-5'>
                    <span
                      style={{
                        backgroundColor: "#017ABF",
                        padding: "0px 10px",
                        color: "#fff",
                      }}>
                      Unlock:
                    </span>{" "}
                    10% .
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-md-7 m-auto'>
            <img
              src='assets/img/platform/tokenomics.png'
              className='img-fluid mt-4 pt-4'
              alt='...'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;

/** @format */

import React from "react";
import CountDown from "./CountDown";

const Hero = () => {
  return (
    <section className='hero-area heroV1'>
      <div className='graphicanimation-area'>
        <div className='ga-set-A'>
          <ul>
            <li>
              <img src='assets/img/graphic/set-a/g-1.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-2.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-3.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-4.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-5.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-6.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-8.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-9.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-10.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-11.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-12.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-13.png' alt='' />
            </li>
            <li>
              <img src='assets/img/graphic/set-a/g-14.png' alt='' />
            </li>
          </ul>
        </div>
      </div>
      <div className='hv1-box'>
        <div className='container'>
          {/* <CountDown /> */}
          <div className='hero-content'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='hero-text'>
                  <h2 data-aos-delay={50} data-aos-duration={1000}>
                    Decentralized Communication
                    <span>platform.</span>
                  </h2>
                  <p data-aos-delay={100} data-aos-duration={1000}>
                    You don’t have to verify your phone number or email to use
                    stealth chat<sup>:SPEP</sup>. Someone can send you a message
                    by just using your BEP-20 address.
                  </p>
                  {/* <a
                    href='https://www.pinksale.finance/launchpad/0x8F822678234D591E8dB758499fC37bE71eE5f663?chain=BSC'
                    rel='noreferrer'
                    target='_BLANK'
                    className='btn-style-h ani'>
                    <img
                      src='https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png'
                      style={{ display: "inline-block", width: 24 }}
                      alt=''
                    />{" "}
                    Join Fair launch
                  </a>
                  &nbsp; */}
                  <span
                    id='single_image'
                    data-fancybox
                    data-type='iframe'
                    data-src='https://www.youtube.com/embed/TLUcgNCrb88?autoplay=1&rel=0&controls=0&showinfo=0'
                    className='btn-style-c'>
                    Watch Intro Video &nbsp;
                    <i className='fa fa-play' aria-hidden='true' />
                  </span>
                  <div className='hero-btn'>
                    <a
                      href='https://stake.secretpep.com'
                      rel='noreferrer'
                      target='_BLANK'
                      className='btn-style-b'>
                      Stake: <sup>SPEP</sup>
                      <i className='flaticon-right' />
                    </a>{" "}
                    &nbsp;
                    <a
                      href='https://vote.secretpep.com'
                      rel='noreferrer'
                      target='_BLANK'
                      className='btn-style-a'>
                      Vote
                      <i className='flaticon-right' />
                    </a>{" "}
                    &nbsp;
                    <a
                      href='https://airdrop.secretpep.com'
                      rel='noreferrer'
                      target='_BLANK'
                      className='btn-style-b mt-3'>
                      Airdrop
                      <i className='flaticon-right' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mt-5 text-end'>
                <div className='hero-img'>
                  <img
                    src='assets/img/platform/3911043.png'
                    alt=''
                    data-aos-delay={100}
                    data-aos-duration={2000}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

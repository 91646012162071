import React from 'react'

const Section4 = () => {
  return (
    <section className='service-area' id='stealth-chat'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <h3 className='mt-3'>Only BEP-20 Address Required</h3>
          <p className='mt-4'>
            Stay Anonymous with only a address. You don’t have to verify
            your phone number or email to use SPEP:Chat. Someone can send you
            a message by just using your BEP-20 address. You can decide to
            receive message from anyone or just from whitelisted addresses.
          </p>
          <br />
          <img
            src='assets/img/platform/3793097.png'
            className='mt-5 img-fluid' alt="..."
          />
          <p className='text-center'>
            <img
              src='assets/img/platform/get-app.png'
              className='mt-5' alt='..'
              width='300px'
            />
            <br />
            <span className='fs-12'>
              Apps are not available yet, please refer to product timeline.
            </span>
          </p>
        </div>
        <div className='col-md-6'>
          <div className='service-box'>
            <ul className='services-list'>
              <li data-aos-delay={50} data-aos-duration={1000}>
                <div className='single-service'>
                  <div className='ss-icon'>
                    <img src='assets/img/platform/screenshot.png' alt='' />
                  </div>
                  <div className='ss-text'>
                    <h4>Anti-Screenshot</h4>
                    <p className='fs-12'>
                      When “Anti-SR” mode is enabled, SPEP insight algorithm
                      completely prevents any one to take screenshot.
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos-delay={100} data-aos-duration={1000}>
                <div className='single-service'>
                  <div className='ss-icon'>
                    <img src='assets/img/platform/biohazard.png' alt='' />
                  </div>
                  <div className='ss-text'>
                    <h4>Nuke Messages</h4>
                    <p className='fs-12'>
                      You can nuke an entire conversation from a single
                      message and both sides of messages will get destruct.
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos-delay={150} data-aos-duration={1000}>
                <div className='single-service'>
                  <div className='ss-icon'>
                    <img src='assets/img/platform/padlock.png' alt='' />
                  </div>
                  <div className='ss-text'>
                    <h4>
                      Encryption{" "}
                      <sup>
                        <small>AES-512 Bit</small>
                      </sup>
                    </h4>
                    <p className='fs-12'>
                      Messages stored on your device are encrypted
                      specifically for you, and can never be opened on new
                      device.
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos-delay={200} data-aos-duration={1000}>
                <div className='single-service'>
                  <div className='ss-icon'>
                    <img src='assets/img/platform/hide.png' alt='' />
                  </div>
                  <div className='ss-text'>
                    <h4>Hide in Plain Sight</h4>
                    <p className='fs-12'>
                      Encrypt secret photos behind a cover image. You can
                      store this covered image in your camera roll.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>  )
}

export default Section4
/** @format */
import React, { useState, useEffect } from "react";
import SplashScreen from "./SplashScreen";
import "./App.scss";
import Router from "./Router";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-269597103-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  return <>{loading ? <SplashScreen /> : <Router />}</>;
}

export default App;

/** @format */

import React from "react";
import "./SplashScreen.css";

function SplashScreen() {
  return (
    <div className='spinner'>
      <div className='dot dot-1'></div>
      <div className='dot dot-2'></div>
      <div className='dot dot-3'></div>
      <div className='dot dot-4'></div>
      <div className='dot dot-5'></div>
    </div>
  );
}

export default SplashScreen;

/** @format */

import React from "react";

const Header = () => {
  return (
    <>
      <header className='qniko-header qhV1'>
        <div className='header-box'>
          <div className='row'>
            <div className='col-md-8'>
              <nav className='main-nav qnikoNav' role='navigation'>
                <input id='QnikoMenu-state' type='checkbox' />
                <label className='QnikoMenu-btn' htmlFor='QnikoMenu-state'>
                  <span className='QnikoMenu-btn-icon' />
                </label>
                <div className='nav-brand'>
                  <a href='/'>
                    <img src='assets/img/logo.png' alt='...' width='180px' />
                  </a>
                </div>
                <ul id='QnikoMenu' className='sm sm-simple qnikoMenu'>
                  <li>
                    <a href='#stealth-wallet'>
                      Stealth Wallet<sup>:SPEP</sup>
                    </a>
                  </li>
                  <li>
                    <a href='#stealth-chat'>
                      Stealth Chat<sup>:SPEP</sup>
                    </a>
                  </li>
                  <li>
                    <a href='#SPEP-token'>SPEP Token</a>
                  </li>
                  <li>
                    <a href='#team' rel='modal:open'>
                      Team
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='col-md-4'>
              <div className='qnRight'>
                <a
                  href='https://t.me/Secret_pep'
                  className='btn-style-a'
                  rel='noreferrer'
                  target='_BLANK'
                  title='join'>
                  Join Telegram
                  <i className='flaticon-right' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id='team' className='modal p-5'>
        <h4>Public Team</h4>
        <br />
        <p>
          We are a group of five enthusiastic team members and yet not ready to
          be public. Anonymity is a big part of decentralized finance, and there
          are legitimate reasons for it: regulations are unclear, developers
          don’t want their private life to be invaded, and creators don’t want
          their public persona to hamper the success of a decentralized project
          that should be controlled by its community and yes we are introverts.
        </p>
        <br />
        <p>
          Trust the idea and decentralized ecosystem not us and this is for sure
          taht we will be making appearances!
        </p>
      </div>
    </>
  );
};

export default Header;

/** @format */

import React from "react";

const Section2 = () => {
  return (
    <section className='how-it-works-area'>
      <div className='container'>
        <div className='row'>
          <div
            className='col-md-4'
            data-aos-delay={50}
            data-aos-duration={1000}>
            <div className='single-hiw'>
              <div className='sh-icon'>
                <img src='assets/img/platform/fast.png' alt='..' />
              </div>
              <div className='sh-text'>
                <h4>Fast, Safe &amp; Easy</h4>
                <p>Powerful and robust features with ease to use interface</p>
              </div>
            </div>
          </div>
          <div
            className='col-md-4'
            data-aos-delay={100}
            data-aos-duration={1000}>
            <div className='single-hiw'>
              <div className='sh-icon'>
                <img src='assets/img/platform/anti.png' alt='..' />
              </div>
              <div className='sh-text'>
                <h4>Without a Trace</h4>
                <p>
                  When a message is destructed or deleted, it is completely gone
                  forever
                </p>
              </div>
            </div>
          </div>
          <div
            className='col-md-4'
            data-aos-delay={150}
            data-aos-duration={1000}>
            <div className='single-hiw'>
              <div className='sh-icon'>
                <img src='assets/img/platform/open.png' alt='..' />
              </div>
              <div className='sh-text'>
                <h4>Open Source</h4>
                <p>
                  SPEP has an open API and source code available community to
                  contribution
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;

import React from 'react'

const Section3 = () => {
  return (
    <section className='about-us-area' id='stealth-wallet'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <div
            className='about-img'
            data-aos-delay={50}
            data-aos-duration={1000}>
            <img src='assets/img/platform/SPEP-wallet.png' alt='' />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='about-text'>
            <div className='section-titleV1'>
              <p data-aos-delay={50} data-aos-duration={1000}>
                Stealth Wallet<sup>:SPEP</sup>
              </p>
              <h3 data-aos-delay={100} data-aos-duration={1000}>
                Hide Transactions <span>With bridging algorithm</span>
              </h3>
            </div>
            <div className='section-text'>
              <h5 data-aos-delay={150} data-aos-duration={1000}>
                This will allow to break the usual chain of transactions and
                removing any traces which connects back to sender or
                receiver.
              </h5>
              <p data-aos-delay={200} data-aos-duration={1000}>
                We are not reinventing the tyre here but taking existing
                transaction model to next level by applying enhanced
                encryption algorithms and simplifying whole process for
                users.
              </p>
              <div
                className='stext-btn'
                data-aos-delay={100}
                data-aos-duration={1000}>
                <a
                  href='#connect-wallet'
                  className='btn-style-b isDisabled'>
                  Connect Wallet
                </a>
                <p className='fs-12'>Please refer to product timeline.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>  )
}

export default Section3
import React from 'react'

const Section7 = () => {
  return (
    <section className='blog-area mt-5 mb-5 pb-5 pt-5'>
    <div className='container'>
      <div className='row'>
        <div
          className='col-md-4'
          data-aos-delay={50}
          data-aos-duration={1000}>
          <div className='section-titleV1'>
            <p>Medium's BLog</p>
            <h3>
              Latest Release <span> &amp; News</span>
            </h3>
            <a
              href='https://secretpep.medium.com/'
              target='_BLANK' rel="noreferrer"
              className='btn-style-b'>
              Open Medium
              <i className='flaticon-right' />
            </a>
          </div>
        </div>
        <div className='col-md-8'>
          <div id='medium-widget' />
        </div>
      </div>
    </div>
  </section>  )
}

export default Section7